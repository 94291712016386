
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import SecondFooter from '@/components/SecondFooter.vue'
import ProductUsers from '@/components/ProductUsers.vue'
import linkGo, { routGo } from '@/hooks/links'
// 引入swiper组件
import { Swiper, SwiperSlide } from "swiper/vue"; //
import SwiperCore, {Navigation,Pagination,Scrollbar,A11y,Autoplay} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
// import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
export default defineComponent({
  name: 'Home',
  components: { 
    SecondFooter,
    ProductUsers,
    Swiper,
    SwiperSlide,
  },
  setup () {
    const router = useRouter()
    const animationImg = ref<number>(0)
    const imgStatus = ref<boolean>(false)
    const move = reactive({
      moveLeftIn: () => {
        animationImg.value = 1
      },
      moveRightIn: () => {
        animationImg.value = 2
      },
      moveTopIn: () => {
        animationImg.value = 3
      },
      moveOut: () => {
        animationImg.value = 0
      },
      jumpGo: (path: string) => {
        router.push(path)
      }
    })
    let swiperOption = {
  spaceBetween: 0,
  slidesPerView: 4, // 一屏显示的slide个数  'auto'
  slidesPerGroup: 1, //每组多少个swiper滑块
  // centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
  // slideToClickedSlide: true, // 点击的slide会居中
  // loop: true, // 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
  scrollbar: { draggable: true },
  grabCursor: true, //抓手光标
  autoplay: {
    delay: 3000,
    disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
    pauseOnMouseEnter: true, //鼠标置于swiper是否时暂停自动切换
  },
  //使用前进后退按钮来控制Swiper切换。
  navigation: true,   // 1默认，在内
  // 2前进、后退按钮放到容器的外面
  // navigation: {
  //   nextEl: ".swiper-button-next",
  //   prevEl: ".swiper-button-prev",
  //   hiddenClass: "button-hidden", //隐藏时的class
  //   disabledClass: "button-disabled", //不可用时的class
  // },
 
  //使用分页器导航
  // pagination: {
  //   clickable: true,
  // },
};
const handleMouseEnter = () => {
  console.log(123)
  alert('123')
    // setIsHovering(true);
  };
const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const openUrl = (e)=>window.open(e)
    onMounted(() => {
      _hmt.push(['_trackPageview', '/home'])
    })
    return {
      animationImg,
      ...toRefs(move),
      imgStatus,
      routGo,
      linkGo,
      onSwiper,
      openUrl,
      modules: [Navigation, Pagination, A11y,Autoplay],
      swiperOption
      
    }
  }
})
